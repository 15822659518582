import { useTranslate } from 'react-admin';
import CustomPopoverMenuItem from '../../popovers/customPopoverMenuItem/CustomPopoverMenuItem';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
type LogoutButtonProps = {
  onClick: () => void;
};

const LogoutButton = (props: LogoutButtonProps) => {
  const { onClick } = props;
  const translate = useTranslate();
  return <CustomPopoverMenuItem text={translate('ra.action.exit')} onClick={onClick} icon={<LogoutOutlinedIcon />} />;
};

export default LogoutButton;
