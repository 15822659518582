import lightTheme from './light';
import darkTheme from './dark';
declare global {
  interface Window {
    _env_: any;
  }
}

export const APP_VERSION: string = process.env.REACT_APP_VERSION || '';
export const PREFIX: string = '/api';
export const MAX_SIZE_AVATAR: number = 100000;
export const MAX_SIZE_LOGO: number = 1000000;
export const AVATAR_WIDTH: number = 480;
export const AVATAR_HEIGHT: number = 640;
export const USER_ROLES = ['', 'Администратор', 'Сотрудник', 'Студент', 'Контрагент'];
export const TIME_RANGE = ['ч.', 'мин.'];
export const TIME_LIST = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];
export const WEEK_DAYS = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
export const MENU_CLOSED_WIDTH = 60;
export const MENU_OPENED_WIDTH = 350;
export const LIGHT_THEME =
  window._env_ &&
  window._env_.LIGHT_THEME &&
  JSON.parse(window._env_.LIGHT_THEME) &&
  JSON.parse(window._env_.LIGHT_THEME).palette
    ? JSON.parse(window._env_.LIGHT_THEME)
    : lightTheme;

export const DARK_THEME =
  window._env_ &&
  window._env_.DARK_THEME &&
  JSON.parse(window._env_.DARK_THEME) &&
  JSON.parse(window._env_.DARK_THEME).palette
    ? JSON.parse(window._env_.DARK_THEME)
    : darkTheme;
