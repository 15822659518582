import React from 'react';
import { useTranslate, Button, UserIdentity } from 'react-admin';
import styles from './AddMenuButton.module.css';
import AddIcon from '@mui/icons-material/Add';
import CustomPopover from '../../popovers/customPopover/CustomPopover';
import AddProfileMenu from '../../popovers/addProfileMenu/AddProfileMenu';

type AddMenuButtonProps = {
  isCollapse: boolean;
  user: UserIdentity;
};

const AddMenuButton = (props: AddMenuButtonProps) => {
  const { isCollapse, user } = props;
  const [anchorElCollapseIcon, setAnchorElCollapseIcon] = React.useState(null);
  const collapseIconRef = React.useRef(null);
  const open = Boolean(anchorElCollapseIcon);
  const translate = useTranslate();

  const handleClose = (e: MouseEvent | TouchEvent) => {
    e.stopPropagation();
    setAnchorElCollapseIcon(null);
  };

  const showPopover = () => {
    if (!open) setAnchorElCollapseIcon(collapseIconRef.current);
    else setAnchorElCollapseIcon(null);
  };

  const closePopover = () => {
    setAnchorElCollapseIcon(null);
  };

  const isAdminApp = () => {
    return user.appAdmins && user.appAdmins.length;
  };

  return user?.role === 1 || isAdminApp() ? (
    <div className={styles.root} ref={collapseIconRef}>
      <Button
        className={styles.button}
        onClick={showPopover}
        sx={{
          '& .MuiButton-startIcon': {
            marginLeft: 0,
            marginRight: 0,
            '& span': {
              fontSize: '14px',
            },
          },
        }}
      >
        {isCollapse ? <AddIcon /> : <span className={styles.text}>{translate('ra.action.create')}</span>}
      </Button>
      <CustomPopover
        open={open}
        anchorEl={anchorElCollapseIcon}
        handleClose={handleClose}
        ChildComponent={() => <AddProfileMenu closePopover={closePopover} isAdmin={user?.role === 1} />}
      />
    </div>
  ) : null;
};

export default AddMenuButton;
