import React from 'react';
import { Button, useNotify, useRefresh, useTranslate, useUnselectAll } from 'react-admin';
import styles from './RemoveGroupFromGroupModal.module.css';
import { IBreadcrumbItem, IGroup } from '../../../types/types';
import dataProvider from '../../../provider/dataProvider';
import CustomPopper from '../../tooltips/customPopper/CustomPopper';
import { transformRoleName } from '../../../utils/utils';
import CloseButton from '../../buttons/closeButton/CloseButton';

type RemoveGroupFromGroupModalProps = {
  closeModal: () => void;
  selectedIds: number[];
  context: string;
  record: IGroup;
  group?: IGroup;
};

const RemoveGroupFromGroupModal = (props: RemoveGroupFromGroupModalProps) => {
  const { closeModal, group, selectedIds, context, record } = props;
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(context === 'members' ? 'membersGroup' : 'memberOfGroup');
  const [groups, setGroups] = React.useState<IGroup[]>([]);

  React.useEffect(() => {
    if (!group) {
      dataProvider.getMany('group', { ids: selectedIds }).then((res) => {
        setGroups(res.data);
      });
    }
    // eslint-disable-next-line
  }, [selectedIds]);

  const onExclude = () => {
    onExcludeGroup(group ? [group] : groups);
    closeModal();
  };

  const onExcludeGroup = (targetGroup: IGroup[]) => {
    try {
      if (context === 'members') {
        Promise.all(
          targetGroup.map((groupItem: IGroup) => {
            return dataProvider.update('group', {
              id: groupItem.id,
              data: {
                memberOf: groupItem.memberOf
                  .filter((item: IBreadcrumbItem) => item.id !== record.id)
                  .map((item: IBreadcrumbItem) => item.id),
              },
              previousData: targetGroup,
            });
          }),
        )
          .then(() => {
            notify('ra.notification.deleted', {
              type: 'success',
              messageArgs: { smart_count: 1 },
              undoable: true,
            });
            unselectAll();
            refresh();
          })
          .catch(() => notify('ra.notification.canceled', { type: 'warning' }));
      } else {
        const targetGroupIds = targetGroup.map(({ id }) => id);
        dataProvider
          .update('group', {
            id: record.id,
            data: {
              memberOf: record.memberOf
                .filter((item: IBreadcrumbItem) => !targetGroupIds.includes(item.id))
                .map(({ id }) => id),
            },
            previousData: record,
          })
          .then(() => {
            notify('ra.notification.deleted', {
              type: 'success',
              messageArgs: { smart_count: 1 },
              undoable: true,
            });
            unselectAll();
            refresh();
          })
          .catch(() => notify('ra.notification.canceled', { type: 'warning' }));
      }
    } catch {
      notify('ra.notification.canceled', { type: 'warning' });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <CloseButton />
      </div>
      {selectedIds?.length > 1 ? (
        <h1 className={styles.header}>{translate('operations.params.GROUP.excludeGroups')}</h1>
      ) : (
        <h1 className={styles.header}>{translate('operations.params.GROUP.excludeGroup')}</h1>
      )}
      {!group ? (
        <span className={styles.text}>
          {`${translate('messages.exclude')} ${
            selectedIds?.length > 1
              ? [translate('operations.params.GROUP.multipleGroups')]
              : [translate('operations.params.GROUP.ofGroup').toLocaleLowerCase()]
          } `}
          {selectedIds?.length > 1 ? (
            <CustomPopper
              title={` (${groups.length})`}
              children={
                <ul>
                  {groups.map((item: IGroup) => (
                    <li className={styles.list_item} key={item.id}>
                      {transformRoleName(item?.name, 58)}
                    </li>
                  ))}
                </ul>
              }
            />
          ) : (
            groups[0]?.name
          )}
        </span>
      ) : (
        <span className={styles.text}>
          {`${translate('messages.exclude')}
              ${translate('operations.params.GROUP.ofGroup').toLocaleLowerCase()}
              ${group?.name}`}
        </span>
      )}
      <div className={styles.buttons}>
        <Button label={translate('ra.action.cancel')} onClick={closeModal} />
        <Button label={translate('ra.action.exclude')} onClick={onExclude} />
      </div>
    </div>
  );
};

export default RemoveGroupFromGroupModal;
