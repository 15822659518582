import React from 'react';
import { Fade, Popper } from '@mui/material';
import './CustomPopper.css';
import { useTheme } from '@mui/material';

type CustomPopperProps = {
  title: string | JSX.Element;
  children: JSX.Element;
};

const CustomPopper = (props: CustomPopperProps) => {
  const { title, children } = props;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const onEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const onClosePopper = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;
  return (
    <div className={'custom_popper'} onMouseLeave={onClosePopper}>
      <div>
        <span onMouseEnter={onEnter}>{title}</span>
      </div>
      <Popper id={id} open={open} anchorEl={anchorEl} transition placement={'right-start'}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={700}>
            <div
              className={'custom_popper-wrapper'}
              style={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.secondary,
              }}
            >
              {children}
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default CustomPopper;
