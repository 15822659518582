import { useTranslate, Button } from 'react-admin';
import styles from './LicErrorModal.module.css';
import CloseButton from '../../buttons/closeButton/CloseButton';

type LicErrorModalProps = {
  closeModal: () => void;
};

const LicErrorModal = (props: LicErrorModalProps) => {
  const { closeModal } = props;
  const translate = useTranslate();

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <CloseButton />
      </div>
      <h1 className={styles.header}>{translate('operations.params.LICENSE.error')}</h1>
      <p>{translate('operations.params.LICENSE.maxUserCount')}</p>
      <p>{translate('operations.params.LICENSE.buyLicense')}</p>
      <div className={styles.buttons_wrapper}>
        <div className={styles.buttons}>
          <Button label={translate('ra.action.close')} onClick={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default LicErrorModal;
