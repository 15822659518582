import { IApproval } from '../types/types';
import { DARK_THEME, LIGHT_THEME } from './constants';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { Shadows } from '@mui/material/styles/shadows';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const getBase64 = (file: any) => {
  return file
    ? new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      })
    : file;
};

export const dateFormat = (
  date: Date | string,
  options: { showTime: boolean; showMilliseconds?: boolean } = {
    showTime: true,
    showMilliseconds: false,
  },
) => {
  const newDate = new Date(date);
  return (
    (date &&
      newDate.toLocaleString().replace(',', '').substring(6, 10) +
        '.' +
        newDate.toLocaleString().replace(',', '').substring(3, 5) +
        '.' +
        newDate.toLocaleString().replace(',', '').substring(0, 2) +
        newDate
          .toLocaleString()
          .replace(',', '')
          .substring(10, options.showTime ? 19 : 10) +
        `${options.showMilliseconds ? `.${newDate.getMilliseconds()}` : ''}`) ||
    ''
  );
};

export const downloadURI = (uri: string) => {
  const link = document.createElement('a');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const transformRoleName = (name: string, maxLength: number): string => {
  if (name.length <= maxLength) return name.replaceAll('/', ' / ');
  const arr = name.split('/');
  return arr
    .map((item: string, index: number) =>
      index === arr.length - 1 && item.length > maxLength ? `${item.slice(0, maxLength)}...` : item,
    )
    .join(' / ');
};

export const dispatchOpenPopoverEvent = () => {
  const openPopoverEvent = new Event('openPopover');
  document.dispatchEvent(openPopoverEvent);
};

export const dispatchCloseDatePickerEvent = () => {
  const closeDatePickerEvent = new Event('closeDatePicker');
  document.dispatchEvent(closeDatePickerEvent);
};

export const getApprovalStatus = (approval: IApproval) => {
  if (approval.approvalList && approval.approvalList.length) {
    const isApproved =
      approval.approvalList.every((item) => item.status === 'approved') ||
      approval.approvalList.some((item) => item.status === 'approved' && item.priority);
    const isCanceled = approval.approvalList.some((item) => item.status === 'canceled');
    if (isApproved) return 'approved';
    else if (isCanceled) return 'canceled';
    else return 'pending';
  }
  return approval.status;
};

export const createLightThemeOptions = () => {
  return {
    mixins: { ...LIGHT_THEME.mixins },
    palette: {
      ...LIGHT_THEME.palette,
      mode: 'light',
    } as PaletteOptions,
    shadows: { ...(LIGHT_THEME.shadows as Shadows) },
    transitions: { ...LIGHT_THEME.transitions },
    typography: { ...(LIGHT_THEME.typography as TypographyOptions) },
    zIndex: { ...LIGHT_THEME.zIndex },
  };
};

export const createDarkThemeOptions = () => {
  return {
    mixins: { ...DARK_THEME.mixins },
    palette: {
      ...DARK_THEME.palette,
      mode: 'dark',
    } as PaletteOptions,
    shadows: { ...(DARK_THEME.shadows as Shadows) },
    transitions: { ...DARK_THEME.transitions },
    typography: { ...(DARK_THEME.typography as TypographyOptions) },
    zIndex: { ...DARK_THEME.zIndex },
  };
};
