import React from 'react';
import { ClickAwayListener } from '@mui/base';
import './CustomPopover.css';
import { Popper, useTheme } from '@mui/material';

type CustomPopoverProps = {
  anchorEl: HTMLAnchorElement | null;
  open: boolean;
  handleClose: (e: MouseEvent | TouchEvent) => void;
  ChildComponent: () => JSX.Element;
};

const CustomPopover = (props: CustomPopoverProps) => {
  const { anchorEl, open, handleClose, ChildComponent } = props;
  const theme = useTheme();

  const onHandleClick = (event: MouseEvent | TouchEvent) => {
    if (!anchorEl?.contains(event.target)) handleClose(event);
  };

  React.useEffect(() => {
    if (open) {
      document.addEventListener('openPopover', onHandleClick);
      return () => document.removeEventListener('openPopover', onHandleClick);
    }
    //eslint-disable-next-line
  }, [open]);

  return (
    <ClickAwayListener onClickAway={onHandleClick}>
      <Popper
        sx={{
          '&.custom_popover': {
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'none',
            border: theme.palette.mode === 'dark' ? `1px solid ${theme.palette.text.secondary}` : 'none',
          },
        }}
        className="custom_popover"
        open={open}
        anchorEl={anchorEl}
      >
        <ChildComponent />
      </Popper>
    </ClickAwayListener>
  );
};

export default CustomPopover;
