import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTheme } from '@mui/material';
import { ReactNode } from 'react';

type CloseButtonProps = {
  Icon?: ReactNode;
  sx?: object;
  onClick?: () => void;
};

const CloseButton = (props: CloseButtonProps) => {
  const { Icon, sx, onClick } = props;

  const theme = useTheme();

  return (
    <div onClick={onClick}>
      {Icon ? (
        Icon
      ) : (
        <CloseOutlinedIcon
          sx={
            sx
              ? sx
              : {
                  ':hover': {
                    borderRadius: '6px',
                    background: theme.palette.action.selected,
                  },
                  color: theme.palette.text.secondary,
                }
          }
        />
      )}
    </div>
  );
};
export default CloseButton;
