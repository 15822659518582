import { useTheme } from '@mui/material';
import styles from './CustomPopoverMenuItem.module.css';
import { useState } from 'react';

type CustomPopoverMenuItemProps = {
  text: string;
  onClick: () => void;
  disable?: boolean;
  icon?: JSX.Element | null;
};

const CustomPopoverMenuItem = (props: CustomPopoverMenuItemProps) => {
  const { text, onClick, disable, icon } = props;
  const onClickWrapper = (e: any) => {
    e.stopPropagation();
    if (onClick) onClick();
  };
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className={disable ? `${styles.custom_menu_item_popover} ${styles.disabled}` : styles.custom_menu_item_popover}
      onClick={onClickWrapper}
      style={{
        backgroundColor: isHovering ? theme.palette.action.hover : 'inherit',
        color: theme.palette.text.primary,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {icon ? <div className={styles.icon}>{icon}</div> : null}
      <div className={styles.custom_menu_item_popover__text}>
        <span>{text}</span>
      </div>
    </div>
  );
};
export default CustomPopoverMenuItem;
