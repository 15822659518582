import { UserMenu, UserMenuProps, useLogout, useGetIdentity } from 'react-admin';
import LogoutButton from '../../buttons/logoutButton/LogoutButton';

const ProfileMenu = (props: UserMenuProps) => {
  const logout = useLogout();
  const { identity: user, isLoading } = useGetIdentity();

  const onLogout = () => {
    logout();
  };

  return (
    <UserMenu {...props} label={isLoading ? 'Профиль' : user?.name}>
      <LogoutButton onClick={onLogout} />
    </UserMenu>
  );
};

export default ProfileMenu;
