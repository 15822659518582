import { createRoot } from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import App from './containers/app/App';
import { APP_VERSION } from './utils/constants';
import { fetchProvider } from './provider/authProvider';

document.title = `Trusted.Net IDM ${APP_VERSION}`;

fetchProvider('/api/settings/app')
  .then((res) => res.json())
  .then((json) => (document.getElementsByTagName('link')[0].href = json.settings.APP_LOGO));

// const themeStorage = JSON.parse(String(localStorage.getItem('RaStore.theme')));
// const theme: RaThemeOptions = themeStorage && themeStorage?.palette?.mode === 'dark' ? createDarkThemeOptions() : createLightThemeOptions();
// localStorage.setItem('RaStore.theme', JSON.stringify(theme));

const root = createRoot(document.getElementById('root')!);

root.render(
  <StyledEngineProvider injectFirst>
    <App />
  </StyledEngineProvider>,
);
