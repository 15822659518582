//@ts-ignore
import ru from 'ra-language-russian';

ru.ra.saved_queries = {
  label: 'Сохраненые фильтры',
  query_name: 'Имя фильтра',
  new_label: 'Сохранить текущий фильтр...',
  new_dialog_title: 'Сохранить текущий фильтр как',
  remove_label: 'Удалить сохраненный запрос',
  remove_label_with_name: 'Удалить фильтр "%{name}"',
  remove_dialog_title: 'Удалить сохраненный фильтр?',
  remove_message: 'Вы уверены, что хотите удалить этот элемент из списка сохраненных фильтров?',
  help: 'Отфильтруйте список и сохраните этот фильтр на потом',
};

ru.ra.notification.approvePhoto = 'Фото будет заменено после подтверждения ответственным лицом!';

ru.ra.notification.approvePhotoErrorResolution = 'Разрешение изображения не должно превышать 640х480 пикселей';

ru.ra.notification.approvePhotoErrorFormat = 'Недопустимый формат изображения';

ru.ra.notification.approvePhotoErrorSize = 'Размер изображения не должен превышать 100 КБ';

ru.ra.notification.codeError = 'Неверный код';

ru.ra.notification.pwdError = 'Неверный текущий пароль';

ru.ra.notification.pwdNewError = 'Новый пароль не соответствует парольным политикам';

ru.ra.notification.approved = 'Одобрено';

ru.ra.notification.refused = 'Отказано';

ru.ra.notification.rolesAdded = 'Роли добавлены';

ru.ra.notification.added = 'Добавлено';

ru.ra.notification.revoked = 'Отозвано';

ru.operations = {
  actions: {
    ADD: 'Добавление',
    POST: 'Создание',
    PUT: 'Изменение',
    DELETE: 'Удаление',
    LOGIN: 'Вход',
    LOGOUT: 'Выход',
    HIDE: 'Cкрыть',
    SHOW: 'Показать',
    SYNC: 'Синхронизация',
    MOVING: 'Перемещение',
    EXCLUDE: 'Исключение',
    RESET: 'Сброс',
  },
  entities: {
    AUTH: 'Авторизация',
    LOGIN: 'Авторизоваться',
    USER: 'Пользователи',
    GROUP: 'Группы',
    APP: 'Приложения',
    ROLE: 'Роли',
    RESOURCES: 'Ресурсы',
    RESOURCE: 'Ресурсы',
    APPROVAL: 'Запросы',
    OPERATION: 'Действие',
    ADMINS: 'Администрирование',
    MEMBERS: 'Доступы',
    CONFIRMEMAIL: 'Подтверждение почты',
    EVENTS: 'События',
    MYPROFILE: 'Мой профиль',
    PROFILE: 'Профиль',
    ADMINISTRATORS: 'Администраторы',
    ADMINISTRATION: 'Администрирование',
    CONNECTORS: 'Коннекторы',
    CONNECTOR: 'Коннекторы',
    SETTINGS: 'Общие настройки',
    MAPPING: 'Сопоставление полей',
    PROFILE_FIELDS: 'Поля профиля',
    USERCUSTOMATTRIBUTESETTING: 'Поля профиля',
    FOLDER: 'Папки',
  },
  params: {
    USER: {
      theRootFolder: 'Корневая папка',
      sAMAccountName: 'Логин',
      givenName: 'Имя',
      sn: 'Фамилия',
      dateOfBirth: 'Дата рождения',
      gender: 'Пол',
      resident: 'Резидент',
      residentYes: 'Да',
      residentNo: 'Нет',
      status_true: 'Разблокировка',
      status_false: 'Блокировка',
      email: 'Электронная почта',
      unicodePwdReset: 'Сброс пароля',
      snils: 'СНИЛС',
      photo: 'Фото',
      accountExpires: 'Срок действия',
      userInformation: 'Информация о пользователе',
      userName: 'Пользователь',
      type: 'Тип',
      department: 'Отдел',
      enterDepartment: 'Введите отдел',
      jobTitle: 'Должность',
      enterPosition: 'Введите должность',
      employment: 'Трудоустройство',
      typeOfEmployment: 'Вид занятости',
      dateOfAdmission: 'Дата приема',
      dateOfDismissal: 'Дата увольнения',
      affilation: 'Принадлежность к ППС',
      groups: 'Группы',
      group: 'Группа',
      basicInfo: 'Основная информация',
      residentialAddress: 'Адрес проживания',
      enterYourAddress: 'Введите адрес проживания',
      userNotFound: 'Пользователь не найден',
      makeCounterparty: 'Сделать контрагентом',
      createUser: 'Создать пользователя',
      ofUser: 'Пользователя',
      assigningRoles: 'Назначение ролей',
      selectRoles: 'Выберите одну или несколько ролей для пользователя',
      notSpecified: 'Не указан',
      male: 'Мужской',
      maleM: 'М',
      female: 'Женский',
      femaleF: 'Ж',
      none: 'Н',
      enterName: 'Введите имя',
      enterLastName: 'Введите фамилию',
      enterLogin: 'Введите логин',
      userPhoto: 'Фото пользователя',
      fileSize: 'Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 100 КБ.',
      campusNumber: 'Кампусный номер',
      enterNumber: 'Введите номер',
      enterPostalCode: 'Введите СНИЛС',
      generate: 'Сгенерировать',
      userProfile: 'Профиль пользователя',
      editProfile: 'Редактировать профиль',
      company: 'Организация',
      enterOrganisation: 'Введите название организации',
      login: 'Логин',
      surname: 'Фамилия',
      birthDate: 'Дата рождения',
      memberOf: 'Вхождение в группы',
      streetAddress: 'Адрес проживания',
      birthday: 'Дата рождения',
      mobile: 'Телефон',
      additionally: 'Дополнительно',
      parentId: 'Перемещение',
      password: 'Пароль',
      cancel: 'отменить',
      title: 'Должность',
      abilityToDelete: 'Удалить можно только контрагентов или папки!',
      uniqueError: 'Дополнительное поле профиля должно быть уникальным',
    },
    EMAIL: {
      confirmEmail: 'Подтвердить почту',
      addEmailAccess: 'Для получения доступа к личному кабинету добавьте к своему профилю электронную почту',
      enterEmailAddress: 'Укажите адрес электронной почты',
      enterTheSentCode: 'Введите код, отправленный на указанный адрес',
      enterYourEmailAddress: 'Введите электронную почту',
      invalidFormat: 'Неверный формат',
      enterTheCode: 'Введите код',
      confirmationCode: 'На этот адрес будет отправлен код подтверждения',
      changeEmail: 'Изменить электронную почту',
      addEmail: 'Добавить электронную почту',
    },
    PHONE: {
      changePhoneNumber: 'Изменить номер телефона',
      addPhoneNumber: 'Добавить номер телефона',
      phoneNumber: 'Номер телефона',
      phone: 'Телефон',
      enterYourPhoneNumber: 'Введите номер телефона',
      enterThePhoneNumber: 'Укажите номер телефона',
    },
    PWD: {
      pwdLength: 'Используйте не менее %{value} символов',
      pwdSpecialCharacters: 'Используйте не менее %{value} специального символа',
      pwdForbiddenCharacters:
        'Пароль содержит запрещённые символы, допустимые значения: %{lowerCaseValue} и %{upperCaseValue}',
      pwdUppercase: 'Используйте не менее %{value} буквы в верхнем регистре',
      pwdNumber: 'Используйте не менее %{value} цифры',
      pwdMatches: 'Новый пароль совпадает с текущим паролем',
      changePassword: 'Изменить пароль',
      pwdContent:
        'Новый пароль должен содержать буквы латинского алфавита, не менее %{length} знаков, в том числе не менее %{number} цифры, не менее %{uppercase} буквы в верхнем регистре и не менее %{specchar} специального символа (%{validspecchar}).',
      unicodePwdCur: 'Текущий пароль',
      enterThePassword: 'Введите пароль',
      newPassword: 'Новый пароль',
      repeatTheNewPassword: 'Повторите новый пароль',
      pwdNotMatch: 'Пароли не совпадают',
      pwdPolicyRequirements: 'Пароль не отвечает требованиям политики',
      unicodePwd: 'Пароль',
      unicodePwdReset: 'Сброс пароля',
      pwdMatch: 'Пароли не совпадают',
    },
    APP: {
      name: 'Наименование',
      description: 'Oписание',
      logo: 'Логотип',
      adminGroupId: 'Группа администраторов',
      url: 'Адрес',
      owner: 'Владелец',
      createdAt: 'Дата создания',
      info: 'Информация',
      appAddress: 'Адрес приложения',
      settings: 'Настройки',
      dateFormat: 'дд.мм.гг',
      rangeDateFormat: 'дд.мм.гггг - дд.мм.гггг',
      symbols: 'символов',
      title: 'Название',
      addApplication: 'Добавить приложение',
      application: 'Приложение',
      imageSize: 'Размер изображения не должен превышать 1 МБ',
      appAddressLimit: 'Адрес приложения не может превышать 255 символов',
      invalidLinkFormat: 'Неверный формат ссылки',
      applicationParameters: 'Параметры приложения',
      applicationName: 'Название приложения ',
      enterName: 'Введите название',
      appDescription: 'Описание приложения',
      enterText: 'Введите текст',
      enterAddress: 'Введите адрес',
      appCover: 'Обложка приложения',
      fileExtension: 'Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.',
      appAddressProtocol: 'Адрес приложения в формате «протокол://доменное имя:порт»',
      connectingGroupOfAdministrators: 'Подключение группы администраторов',
      membersWillManageTheApplication: 'Укажите группу, участники которой будут управлять приложением.',
      connectingUserGroup: 'Подключение группы пользователей',
      membersWillUseTheRole: 'Укажите группу, участники которой будут использовать роль.',
      applicationProfile: 'Профиль приложения',
      applicationNotFound: 'Приложение не найдено',
      displayingColumns: 'Отображение столбцов',
    },
    MONTH: {
      january: 'Январь',
      february: 'Февраль',
      march: 'Март',
      april: 'Апрель',
      may: 'Май',
      june: 'Июнь',
      july: 'Июль',
      august: 'Август',
      september: 'Сентябрь',
      october: 'Октябрь',
      november: 'Ноябрь',
      december: 'Декабрь',
    },
    DAYS: {
      sunday: 'Вс',
      monday: 'Пн',
      tuesday: 'Вт',
      wednesday: 'Ср',
      thursday: 'Чт',
      friday: 'Пт',
      saturday: 'Сб',
      ok: 'Применить',
      today: 'Сегодня',
      yesterday: 'Завтра',
      last7Days: 'Последние 7 дней',
      day: 'День',
      year: 'Год',
    },
    ROLE: {
      name: 'Наименование',
      description: 'Oписание',
      adminGroupId: 'Группа администраторов',
      userGroupId: 'Группа пользователей',
      owner: 'Владелец',
      roleName: 'Название роли',
      roleParameters: 'Параметры роли',
      nameLimit: 'Название не может превышать 50 символов',
      roleDescription: 'Описание роли',
      roleProfile: 'Профиль роли',
      assignRole: 'Назначить роль',
      move: 'Переместить',
      paste: 'Вставить',
      category: 'Категория',
      useByDefault: 'Выбрать при групповом предоставлении доступа',
      enableInMassApproval: 'Выбор при групповом предоставлении доступа',
    },
    RESOURCE: {
      name: 'Наименование',
      description: 'Oписание',
      adminGroupId: 'Группа администраторов',
      userGroupId: 'Группа пользователей',
      owner: 'Владелец',
      resource: 'Ресурс',
      resourceName: 'Название ресурса',
      resourceParameters: 'Параметры ресурса',
      nameLimit: 'Название не может превышать 50 символов',
      resourceDescription: 'Описание ресурса',
      resourceProfile: 'Профиль ресурса',
      assignRole: 'Назначить роль',
      resourceAddress: 'Адрес ресурса',
      url: 'Адрес',
      resourceAddressProtocol: 'Адрес ресурса в формате «протокол://доменное имя:порт»',
      useApprovalList: 'Требует согласования для доступа',
      inheritedResource: 'Наследуемые роли',
      resourceNotFound: 'Ресурс не найден',
      membersWillManageTheResource: 'Укажите группу, участники которой будут управлять ресурсом.',
      deciderIds: 'Список согласования доступа',
      deciders: 'Пользователь для согласования',
    },
    APPROVAL: {
      approvalPhoto: 'Смена фото',
      photo: 'Смена фото',
      access: 'Запрос доступа',
      comment: 'Комментарий',
      status: 'Статус',
      status_approved: 'Одобрено',
      status_canceled: 'Отказано',
      approveAccess: 'Запросить доступ',
      approveAccessMore: 'Запросить еще',
      approvalSend: 'Запрос отправлен!',
      inputComment: 'Укажите комментарий к запросу',
      approvalStatus: 'Статус запроса доступен в разделе «Запросы».',
      approvalAdminStatus:
        'Пользователь получит доступ после согласования. Запрос создан и отправлен согласующим. Статус запроса доступен в разделе «Запросы»',
      info: 'Информация о запросе',
      aboutTheRequest: 'О запросе',
      approvalList: 'Лист согласования',
      waitApproval: 'Ожидают согласования',
      changePhoto: 'Запрос на изменение фото',
      forAccess: 'Запрос на доступ',
      role: 'Запрашиваемая роль',
      resource: 'Запрашиваемый ресурс',
      createdAt: 'Дата создания запроса',
      statusReq: 'Статус запроса',
      pending: 'На рассмотрении',
      approved: 'Одобрено',
      canceled: 'Отказано',
      author: 'Автор',
      reqAuthor: 'Автор запроса',
      authorComment: 'Комментарий автора',
      prevPhoto: 'Прежнее фото профиля',
      newPhoto: 'Новое фото профиля',
      executor: 'Исполнитель',
      reason: 'Причина',
      updatedAt: 'Дата изменения',
      updatedAtReq: 'Дата изменения запроса',
      type: 'Тип',
      notFound: 'Не найден',
      whenCreated: 'Создан',
      requestObject: 'Объект запроса',
      requestToChangeMail: 'Запрос на изменение почты',
    },
    POPOVER: {
      editSettings: 'Редактировать настройки',
      resetPassword: 'Сбросить пароль',
    },
    STATUS: {
      any: 'Любой',
      success: 'Успешно',
      fail: 'Неуспешно',
      active: 'Активный',
      mistake: 'Ошибка',
      disabled: 'Заблокированный',
    },
    GROUP: {
      ofGroup: 'Группу',
      createGroup: 'Создать группу',
      groupParameters: 'Параметры группы',
      groupName: 'Название группы',
      groupDescription: 'Описание группы',
      status: 'Статус',
      existGroup: 'Группа с таким названием уже существует',
      groupProfile: 'Профиль группы',
      groupMemberOf: 'Входит в группы',
      addGroup: 'Добавить группу',
      groupActive: 'Группа активна',
      statusActive: 'Активна',
      statusBlocked: 'Не активна',
      name: 'Наименование',
      description: 'Описание',
      removeUser: 'Исключить пользователя',
      removeUsers: 'Исключить пользователей',
      excludeGroup: 'Исключить группу',
      excludeGroups: 'Исключить группы',
      removeGroup: 'Удалить группу',
      groupIsNotEmpty: 'Группа %{name} подключена к:',
      fewGroupsIsNotEmpty: 'Некоторые группы содержат пользователей или другие группы:',
      whenRemoveMessage: 'При удалении группы вложенные группы и пользователи не удаляются.',
      removeManyGroupsQuestion: 'Вы действительно хотите удалить несколько групп?',
      multipleGroups: 'несколько групп',
      active: 'Активность',
      memberOf: 'Вхождение в группы',
    },
    FOLDER: {
      ofFolder: 'Папку',
      createFolder: 'Создать папку',
      editFolder: 'Редактировать папку',
      folderName: 'Название папки',
      existFolder: 'Папка с таким названием уже существует',
      existFolderName: 'Папка с таким названием %{name} уже существует ',
      folderMemberOf: 'Входит в папки',
      addFolder: 'Добавить папку',
      name: 'Наименование',
      removeFolderTitle: 'Удалить папку',
      removeFolderTitleMany: 'Удалить папки',
      removeFolderAndUserTitleMany: 'Удалить пользователей и папки',
      removeFolder: 'Вы действительно хотите удалить папку %{name}?',
      resetPasswordError: 'В папке %{name} нет пользователей для сброса пароля',
      removeFolderAndUser: 'Вы действительно хотите удалить пользователей и папки',
      removeFolderMany: 'Вы действительно хотите удалить несколько папок?',
      cantRemoveFolder: 'Папка %{name} содержит пользователей или другие папки',
      cantRemoveFolderMany: 'Папки содержат пользователей или другие папки',
      removeFolderAccept: 'Вы действительно хотите необратимо удалить папку и её содежимое?',
      resetPasswordAccept: 'Вы действительно хотите сбросить пароли ?',
      removeFolderAcceptMany: 'Вы действительно хотите необратимо удалить папки и их содежимое?',
      impossibleToMoveFolderInsideYourself: 'Невозможно переместить папку внутрь себя',
      totalUsersInTheFolders: 'Всего пользователей',
      passwordReset: 'Сброшен пароль',
      passwordResetErrors: 'Ошибки сброса пароля',
      password: 'Пароль',
      passwordReseting: 'Пожалуйста подождите, идет сброс паролей...',
      passwordResetingError: 'При выполнении операции произошли ошибки',
      passwordResetingErrorInfo: 'Не удалось сбросить пароли:',
    },
    CONNECTOR: {
      connectorName: 'Название коннектора',
      enterConnectorName: 'Введите название коннектора',
      serverAddress: 'Адресс сервера (ldap_url)',
      service_address: 'Адрес сервиса',
      enterServerAddress: 'Введите адресс сервера',
      enterServiceAddress: 'Введите адресс сервиса',
      serviceAddressExample: 'Адрес сервиса в формате',
      serviceAddressFormat: 'http://{имя веб сервиса}/{имя опубликованной базы}/ws/seimessage?wsdl',
      searchBase: 'База поиска (ldap_base)',
      enterSearchBase: 'Введите базу поиска',
      service_name: 'Имя сервиса',
      serviceNameExample: 'По умолчанию DemoSSL',
      administratorLogin: 'Логин администратора',
      administratorLdapLogin: 'Логин администратора (ldap_admin_dn)',
      enterAdministratorLogin: 'Введите логин администратора',
      administratorPassword: 'Пароль администратора',
      authorizationToken: 'Токен авторизации',
      enterAuthorizationToken: 'Введите токен авторизации',
      administratorLdapPassword: 'Пароль администратора (ldap_admin_pwd)',
      enterAdministratorPassword: 'Введите пароль администратора',
      pwdEditable: 'Запретить смену пароля',
      searchFilter: 'Фильтр поиска (ldap_filter)',
      enterSearchFilter: 'Введите фильтр поиска',
      checkTheConnection: 'Проверить подключение',
      linkBy: 'Связать по',
      linkByInfo:
        'Связать по : установка флага означает, что по данному полю будет происходить поиск и связывание пользователей систем. Значения полей должны быть уникальными.',
      internalField: 'Внутреннее поле',
      externalField: 'Внешнее поле',
      mappingUserProfileFields: 'Сопоставление полей профиля пользователя',
      turnOn: 'Включить',
      connectionParameters: 'параметры подключения',
      matchingFields: 'сопоставление полей',
      synchronizationRules: 'правила синхронизации',
      createConnector: 'Создать коннектор',
      disconnect: 'Отключить',
      ADConnector: 'Active Directory',
      ZICConnector: '1С',
      MoodleConnector: 'Moodle',
      BitrixConnector: 'Bitrix',
      AldproConnector: 'ALD Pro',
      type: 'Тип',
      lastSync: 'Дата синхронизации',
      toSync: 'Синхронизировать',
      saveAndSync: 'Сохранить и синхронизировать',
      fromAD: 'В IDM.Trusted.Net по данным %{name}',
      fromIDM: 'В %{name} по данным IDM.Trusted.Net',
      creating: 'Создание',
      editing: 'Редактирование',
      removing: 'Удаление',
      syncSettings: 'настройки синхронизации',
      autoSync: 'Автоматическая синхронизация',
      whenHasChangingIDM: 'При изменении в IDM.Trusted.Net',
      everyTime: 'каждые',
      daily: 'Ежедневно',
      weekly: 'Еженедельно',
      ConnectionCompletedSuccessfully: 'Подключение к %{name} проведено успешно',
      FailedToConnectTo: 'Не удалось подключиться к %{name}',
      CheckTheConnectionParameters: 'Проверьте параметры подключения',
      removeTheConnector: 'Удалить коннектор',
      removeTheConnectors: 'Удалить коннекторы',
      removeConnector: 'Вы действительно хотите удалить коннектор %{name}?',
      removeManyConnector: 'Вы действительно хотите удалить несколько коннекторов',
      active: 'Активность',
      syncRules: 'Правила синхронизации',
      switchOn: 'Включить коннектор',
      switchOff: 'Отключить коннектор',
      switchOnQuestion: 'Вы действительно хотите включить коннектор %{name}?',
      switchOffQuestion: 'Вы действительно хотите отключить коннектор %{name}?',
      confirmSync: 'Выполнить синхронизацию коннектора %{name}?',
      success: 'Успешно',
      successSync: 'Синхронизация выполнена успешно!',
      error: 'Ошибка',
      errorSync: 'При синхронизации произошла ошибка!',
      syncLoading: 'Выполняется синхронизация, подождите',
      syncResultsSystem: 'Результаты синхронизации',
      in: 'В %{name}:',
      inAnExternalSystem: 'Во внешней системе:',
      syncUsers: 'Пользователи',
      syncGroups: 'Группы',
      syncAdded: 'Добавлено',
      syncUpdated: 'Обновлено',
      syncDeleted: 'Удалено',
      syncSkipped: 'Пропущено',
      ldap_filter: 'Фильтр поиска',
      syncSchedule: 'Настройки синхронизации',
      syncOnChange: 'При изменении в IDM',
      name: 'Название коннектора',
      ldap_url: 'Адресс сервера',
      ldap_base: 'База поиска',
      ldap_admin_dn: 'Логин администратора',
      ldap_admin_pwd: 'Пароль администратора',
      zic_admin_pwd: 'Пароль администратора',
      zic_admin_login: 'Логин администратора',
      generatePwd: 'Генерировать один пароль для новых пользователей',
      generate: 'Сгенерировать',
      prohibitPasswordChange: 'Запретить смену пароля в системе при изменении пароля в %{value}',
      passwordTab: 'Пароли',
    },
    MAPPING: {
      ownerField: 'Внутреннее поле',
      externalField: 'Внешнее поле',
      link: 'Связывание по полю',
      errorUserAttributes: 'Ошибка, не удалось получить основные поля',
      errorUserCustomAttributes: 'Ошибка, не удалось получить дополнительные поля',
    },
    FIELDS: {
      title: 'Дополнительные поля профиля пользователя',
      name: 'Наименование',
      status: 'Статус',
      type: 'Тип',
      description: 'Описание',
      create: 'Создать поле',
      edit: 'Редактировать настройки',
      delete: 'Удалить',
      removeField: 'Удалить поле',
      removeManyFields: 'Удалить поля',
      confirmRemoveField: 'Вы действительно хотите удалить дополнительное поле %{name}?',
      confirmRemoveManyField: 'Вы действительно хотите удалить несколько дополнительных полей?',
      warningRemoveField: 'Заполненные значения в полях профиля пользователя удалятся без возможности восстановления.',
      warningRemoveFieldMapping: 'В настройках коннектора поле удалится из списка сопоставления полей',
      fieldName: 'Название поля',
      descriptionOfTheField: 'Описание поля',
      required: 'Обязательное',
      unique: 'Уникальное',
      editable: 'Редактируемое',
      active: 'Активное',
      additionalSettings: 'Дополнительные настройки',
      fieldType: 'Тип поля',
      maximumLength: 'Максимальная длина',
      defaultValue: 'Значение по умолчанию',
      maximumLengthInfo: 'Максимальная длина не более 250 символов',
      errorMappingUserProfileFields: 'Поле с таким названием уже существует',
      optionsToChoose: 'Варианты для выбора',
      enterValues: 'Введите значения через ; (точку с запятой)',
      enabledByDefault: 'Включено по умолчанию',
      text: 'text',
      dropdownList: 'list',
      switch: 'switch',
      textField: 'Текстовое поле',
      listField: 'Выпадающий список',
      toggleField: 'Переключатель',
    },
    USERCUSTOMATTRIBUTE: {
      value: 'Название поля',
    },
    USERCUSTOMATTRIBUTESETTING: {
      name: 'Название поля',
      description: 'Описание поля',
      type: 'Тип поля',
      active: 'Активное поле',
      required: 'Обязательное поле',
      editable: 'Редактируемое поле',
      unique: 'Уникальное поле',
      enabledByDefault: 'Включено по умолчанию',
      length: 'Максимальная длина',
      defaultValue: 'Значение по умолчанию',
      enterValues: 'Варианты для выбора',
    },
    LICENSE: {
      error: 'Ошибка',
      maxUserCount: 'Превышено количество лицензируемых пользователей!',
      buyLicense: 'Приобретите лицензию.',
    },
  },
};
ru.approvals = {
  TABS: {
    toApproval: 'Требуют согласования',
    myApprovals: 'Мои запросы',
    allApprovals: 'Все запросы',
  },
  FILTERS: {
    pending: 'На рассмотрении',
    approved: 'Одобрено',
    canceled: 'Отказано',
    photo: 'Изменение фото',
    access: 'Доступ',
  },
};

ru.organisation = {
  language: 'Ru',
  name: '© 2022, Цифровые технологии',
  lkVersion: 'Версия ЛК',
  apiVersion: 'Версия API',
};

ru.ra.action.choose = 'Выбрать';

ru.ra.action.search = 'Поиск';

ru.ra.action.revoke = 'Отозвать';

ru.ra.action.create = 'Cоздать';

ru.ra.action.change = 'Изменить';

ru.ra.action.add = 'Добавить';

ru.ra.action.edit = 'Редактировать';

ru.ra.action.confirm = 'Подтвердить';

ru.ra.action.cancel = 'Отмена';

ru.ra.action.delete = 'Удалить';

ru.ra.action.download = 'Загрузить';

ru.ra.action.block = 'Заблокировать';

ru.ra.action.save = 'Cохранить';

ru.ra.action.abort = 'Oтменить';

ru.ra.action.unblock = 'Разблокировать';

ru.ra.action.comeback = 'Назад';

ru.ra.action.approve = 'Одобрить';

ru.ra.action.deny = 'Отказать';

ru.ra.action.skip = 'Пропустить';

ru.ra.action.display = 'Отображение';

ru.ra.action.exit = 'Выйти';

ru.ra.action.continue = 'Продолжить';

ru.ra.action.actions = 'Действия';

ru.ra.action.abort = 'Oтменить';

ru.ra.action.exclude = 'Исключить';

ru.ra.action.toClose = 'закрыть';

ru.ra.action.screenshot = 'Сделать снимок';

ru.ra.action.clearScreenshot = 'Очистить';

ru.ra.action.toggle_theme = 'Переключить тему';

ru.messages = {
  requiredField: '"Обязательное поле" (Вы не можете оставить поле пустым).',
  expirationDate: 'Срок действия полномочий',
  expirationDateTo: 'Полномочия действуют до',
  limitExpiration: 'Ограничить срок действия полномочий',
  notLimitExpiration: 'Не ограничивать срок действия полномочий',
  addUser: 'Добавить пользователя',
  addAdmin: 'Добавить администратора',
  addRole: 'Добавить роль',
  addResource: 'Добавить ресурс',
  revokeAccess: 'Отозвать доступ',
  revokeAccessTo: 'Отозвать доступ у %{user} к %{name}?',
  revokeAccessManyTo: 'Отозвать доступ у %{user} к нескольким ролям?',
  revokeAccessMessage: 'При отзыве доступа %{users} доступ к',
  revokeAccessRoleMessage: 'При отзыве доступа к %{role} пользователь теряет доступ к',
  removeAppTitle: 'Удалить приложение',
  removeApp: 'Вы действительно хотите удалить приложение %{name}?',
  removeManyApps: 'Вы действительно хотите удалить несколько приложений (%{count})',
  cantRemoveApp: 'Вы не можете удалить приложение %{name}, пока его роли назначены пользователям.',
  cantRemoveManyApps: 'Вы не можете удалить приложение, пока его роли назначены пользователям.',
  removeRoleTitle: 'Удалить роль',
  removeResourceTitle: 'Удалить ресурс',
  removeRole: 'Вы действительно хотите удалить роль',
  removeResource: 'Вы действительно хотите удалить ресурс',
  removeManyRoles: 'Вы действительно хотите удалить несколько ролей (%{count})',
  removeManyResources: 'Вы действительно хотите удалить несколько ресурсов (%{count})',
  cantRemoveRole: 'Вы не можете удалить роль %{name}, пока она назначена пользователям.',
  cantRemoveResource: 'Вы не можете удалить ресурс %{name}, пока его роли назначены пользователям.',
  cantRemoveManyRoles: 'Вы не можете удалить роль, пока она назначена пользователям.',
  cantRemoveManyResources: 'Вы не можете удалить ресурс, пока его роли назначены пользователям.',
  thenGiveAccessMessage: 'При предоставлении доступа к',
  userWillTakeAccessMessage: ' %{role} пользователь (%{countUser}) получит доступ к',
  fromUser: 'пользователя',
  fromUsers: 'пользователей',
  giveAccess: 'Предоставить доступ',
  accessGranted: 'доступ предоставлен',
  accessRequested: 'доступ запрошен',
  inputRevokeReason: 'Укажите причину отзыва ролей',
  chooseGroup: 'Выбрать группу',
  chooseRole: 'Выбор роли',
  inputReason: 'Укажите причину',
  inputRefusalReason: 'Укажите причину отказа',
  commentText: 'Текст комментария',
  administration: 'администрированию',
  appAdministration: 'администрированию приложений',
  resourceList: 'аналогичным ролям родительского и дочерних ресурсов',
  roleList: 'ролям',
  resourceAdministration: 'администрированию ресурсов',
  roleAdministration: 'Администрированию роли',
  inheritedRole: 'Наследуемой роли',
  usersWillReceive: 'пользователи получат',
  userWillReceive: 'пользователь получит',
  accessTo: 'доступ к',
  assigningRole: 'При назначении роли',
  assigningAccessTo: 'При назначении доступа к',
  resourcesWithoutDefaultRoles: 'Для ресурсов нет ролей, выбранных при групповом предоставлении доступа',
  userAlreadyHasRoles: 'Пользователю %{user} уже были назначены роли',
  recommendedOperation: 'Вы можете назначить другие роли при одиночном ободрении запроса',
  selectedUsersWillHaveAccessTo: 'выбранные пользователи получат доступ к',
  userWillHaveAccessTo: 'пользователь получит доступ к',
  willHaveAccessTo: 'получит доступ к',
  blockUser: 'Заблокировать пользователя',
  reqBlockUser: 'Вы действительно хотите заблокировать',
  multipleUsers: 'несколько пользователей',
  accessDeny: 'Отказать в доступе',
  willLost: 'потеряет',
  willLose: 'потеряют',
  deleteUsersMany: 'Удалить пользователей',
  removeManyUsers: 'Вы действительно хотите удалить несколько пользователей?',
  removeUser: 'Вы действительно хотите удалить пользователя %{name}',
  notFound: 'ничего не найдено',
  alreadyConnectedTo: 'уже подключена к',
  limitAccountExpiration: 'Ограничить срок действия учетной записи',
  notLimitAccountExpiration: 'Не ограничить срок действия учетной записи',
  unblockUser: 'Разблокировать пользователя',
  unblock: 'Вы действительно хотите разблокировать',
  accountIsValidUntil: 'Учетная запись действует до',
  remove: 'Вы действительно хотите удалить',
  exclude: 'Вы действительно хотите исключить',
  deleteUser: 'Удалить пользователя',
  emptyList: 'Список пуст',
  accountExpires: 'Срок действия записи',
  still: 'еще',
  interValue: 'Введите значение',
  getChecking: 'Идет проверка, подождите',
  invalidLoginOrPwd: 'Неправильный логин или пароль',
  useAdminEmail: 'Пожалуйста, обратитесь к администратору %{email}',
  uploadPhoto: 'Загрузить фото',
  devicesNotFound: 'Устройства не найдены',
  fromFileSystem: 'С компьютера',
  fromWebCam: 'С веб-камеры',
};

const customRuMessages: any = {
  ...ru,
  resources: {
    user: {
      name: 'Пользователь |||| Пользователи',
      fields: {
        givenName: 'Имя',
        whenCreated: 'Создан',
        description: 'Описание',
        status: 'Статус',
        memberOf: 'Группы AD',
        roles: 'Роли',
        role: 'Тип пользователя',
        accountExpires: 'Срок действия записи',
        limited: 'Ограничен',
        unlimited: 'Не ограничен',
        type: 'Тип',
      },
    },
    operation: {
      name: 'Событие |||| События',
      fields: {
        type: 'Операция',
        entry: 'Сущность',
        identity: 'Идентификатор',
        params: 'Параметр',
        ownerId: 'Автор',
        createdAt: 'Дата создания',
        success: 'Статус',
        comment: 'Причина',
      },
    },
    app: {
      name: 'Приложение |||| Приложения',
    },
    approval: {
      name: 'записей',
      fields: {
        createdAt: 'Дата создания',
        updatedAt: 'Дата изменения',
      },
    },
    role: {
      name: 'записей',
    },
    adminApp: {
      name: 'записей',
    },
    reports: {
      name: 'Отчеты',
      types: {
        1: 'Права доступа',
        2: 'Изменения прав',
        3: 'События',
      },
    },
  },
};

export default customRuMessages;
