import React from 'react';
import { Button, useNotify, useRefresh, useTranslate, useUnselectAll } from 'react-admin';
import styles from './RemoveUserFromGroupModal.module.css';
import { IProfile } from '../../../types/types';
import dataProvider from '../../../provider/dataProvider';
import CustomPopper from '../../tooltips/customPopper/CustomPopper';
import { transformRoleName } from '../../../utils/utils';
import CloseButton from '../../buttons/closeButton/CloseButton';

type RemoveUserFromGroupModalProps = {
  closeModal: () => void;
  user?: IProfile;
  groupId: number;
  selectedIds: number[];
};

const RemoveUserFromGroupModal = (props: RemoveUserFromGroupModalProps) => {
  const { closeModal, user, groupId, selectedIds } = props;
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('usersGroup');
  const [users, setUsers] = React.useState<IProfile[]>([]);

  const onExclude = () => {
    Promise.all(user ? [onRemoveUser(user.id)] : selectedIds.map((item: number) => onRemoveUser(item)))
      .then(() => {
        refresh();
        notify('ra.notification.revoked', {
          type: 'success',
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
      })
      .catch(() => notify('ra.notification.canceled', { type: 'warning' }))
      .finally(() => {
        unselectAll();
        closeModal();
      });
  };

  const onRemoveUser = (id: number) => {
    if (user) {
      const memberOf = user.memberOf.filter((item) => item.id !== groupId).map(({ id }) => id);
      return new Promise((resolve) =>
        resolve(
          dataProvider.update('user', {
            id,
            data: { memberOf },
            previousData: user,
          }),
        ),
      );
    } else {
      const user = users.find((user: IProfile) => user.id === id);
      if (user) {
        const memberOf = user.memberOf.filter((item) => item.id !== groupId).map(({ id }) => id);
        return new Promise((resolve) =>
          resolve(
            dataProvider.update('user', {
              id,
              data: { memberOf },
              previousData: user,
            }),
          ),
        );
      }
    }
  };

  React.useEffect(() => {
    if (!user) {
      dataProvider.getMany('user', { ids: selectedIds }).then((res) => {
        setUsers(res.data);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <CloseButton />
      </div>
      {selectedIds?.length > 1 ? (
        <h1 className={styles.header}>{translate('operations.params.GROUP.removeUsers')}</h1>
      ) : (
        <h1 className={styles.header}>{translate('operations.params.GROUP.removeUser')}</h1>
      )}
      {!user ? (
        <span className={styles.text}>
          {`${translate('messages.exclude')} ${
            selectedIds?.length > 1 ? [translate('messages.multipleUsers')] : [translate('messages.fromUser')]
          } `}
          {selectedIds?.length > 1 ? (
            <CustomPopper
              title={` (${users.length})`}
              children={
                <ul>
                  {users.map((item: IProfile) => (
                    <li className={styles.list_item} key={item.id}>
                      {transformRoleName(
                        item?.displayName && item?.displayName.trim()
                          ? String(item?.displayName)
                          : `${item?.givenName} ${item?.surname}`,
                        58,
                      )}
                    </li>
                  ))}
                </ul>
              }
            />
          ) : users[0]?.displayName && users[0]?.displayName.trim() ? (
            users[0]?.displayName
          ) : (
            `${users[0]?.givenName} ${users[0]?.surname}`
          )}
        </span>
      ) : (
        <span className={styles.text}>
          {`${translate('messages.exclude')} ${translate('messages.fromUser')} ${
            user?.displayName && user?.displayName.trim()
              ? `${user?.displayName}`
              : `${user?.givenName} ${user?.surname}`
          }`}
        </span>
      )}
      <div className={styles.buttons}>
        <Button label={translate('ra.action.cancel')} onClick={closeModal} />
        <Button label={translate('ra.action.exclude')} onClick={onExclude} />
      </div>
    </div>
  );
};

export default RemoveUserFromGroupModal;
