import { Layout, LayoutProps, Sidebar, SidebarProps, useSidebarState } from 'react-admin';
import CustomHeader from '../customHeader/CustomHeader';
import CustomMenu from '../menu/customMenu/CustomMenu';
import { MENU_CLOSED_WIDTH, MENU_OPENED_WIDTH } from '../../utils/constants';

const MySidebar = (props: SidebarProps) => {
  const [open] = useSidebarState();
  return (
    <Sidebar
      sx={{
        '& .RaSidebar-paper': {
          width: open ? MENU_OPENED_WIDTH : MENU_CLOSED_WIDTH,
        },
      }}
      {...props}
    />
  );
};

const CustomLayout = (props: LayoutProps) => (
  <Layout {...props} appBar={CustomHeader} menu={CustomMenu} sidebar={MySidebar} />
);

export default CustomLayout;
