import simpleRestDataProvider from 'ra-data-simple-rest';
import { GetOneParams, fetchUtils } from 'react-admin';
import { fetchProvider } from './authProvider';
// import {fetchUtils, GetOneParams} from 'react-admin';
// import {IChangeProfile} from "../utils/types";
// import {PREFIX} from "../utils/constants";
// import data from './data';

// const baseDataProvider = simpleRestDataProvider('http://idm.trusted.plus:8080/api');
const fetchJson = (url: string, options: any = {}) => {
  options.user = {
    authenticated: true,
    token: localStorage.getItem('token'),
  };
  return fetchUtils.fetchJson(url, options);
};
const baseDataProvider = simpleRestDataProvider('/api', fetchJson);
// const httpClient = fetchUtils.fetchJson;

// type paramsProps = {
//   pagination?: {
//     page: number,
//     perPage: number,
//   },
//   sort?: {
//     field: string,
//     order: string,
//   },
//   filter?: any,
//   id?: string,
// }

// const getPagination = (page: number, perPage: number): number[] => {
//   const start: number = (page - 1) * perPage;
//   const end: number = (page * perPage) - 1;
//   return [start, end];
// }

const dataProvider = {
  ...baseDataProvider,
  getOne: (resource: string, params: GetOneParams) => {
    if (resource === 'profile') {
      return Promise.resolve(
        fetchProvider(`/api/user/${params.id}`).then(async (res) => {
          return {
            data: { ...(await res.json()) },
          };
        }),
      );
    }
    return baseDataProvider.getOne(resource, params);
  },
  // getList: (resource: string, params: GetListParams) => {
  //   if (resource === 'users') {
  //     console.log(params);
  //     return Promise.resolve(httpClient(`/user?range=${JSON.stringify(getPagination(params.pagination.page, params.pagination.perPage))}`, {method: 'GET'}).then(({ json, headers }) => ({
  //         data: json,
  //         total: Number(headers.get('x-total-count')),
  //       })))
  //   }
  //   if (resource === 'apps') {
  //     return Promise.resolve(
  //       {data: data.apps, total: data.apps.length}
  //     )
  //   }
  //   return baseDataProvider.getList(resource, params);
  // },
  /*
  getOne: (resource: string, params: GetOneParams) => {
    if (resource === 'profile') {
      return Promise.resolve(httpClient(`/user/${params.id}`, {method: 'GET'}).then(({ json }) => ({
          data: {...json, id: json.username},
        })))
    }
    return baseDataProvider.getOne(resource, params);
  },
  changeProfile: (profile: IChangeProfile, login: string) =>
    httpClient(`/user/${login}`, {method: 'PATCH', body: JSON.stringify(profile)}).then(({ json }) => ({
      data: json,
    })),
  createUser: (profile: IChangeProfile) =>
    httpClient(`/user`, {method: 'POST', body: JSON.stringify(profile)}).then(({ json }) => ({
      data: json,
    })),
  deleteUser: (login: string) =>
    httpClient(`/user/${login}`, {method: 'DELETE'}).then(({ json }) => ({
      data: json,
    })),
  changePassword: (passwords: string, login: string) =>
    httpClient(`${PREFIX}/profile/pwd/${login}`, {method: 'POST', body: JSON.stringify(passwords)}).then(({ json }) => ({
      data: json,
    })),
  changeActiveUser: (status: string, login: string) =>
    httpClient(`${PREFIX}/profile/status/${login}/${status}`, {method: 'POST'}).then(({ json }) => ({
      data: json,
    })),
  getUserPhoto: (login: string) =>
    httpClient(`${PREFIX}/profile/image/${login}`, {method: 'GET'}).then(({ json }) => ({
      data: json,
    })),
  changeUserPhoto: (photo: any, login: string) =>
    httpClient(`${PREFIX}/profile/image/${login}`, {method: 'POST', body: JSON.stringify({fileName: photo})}).then(({ json }) => ({
      data: json,
    })),
    */
};

export default dataProvider;
